import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionFive.css"
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoneIcon from '@mui/icons-material/Done';
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionFive = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([]);
    const [stepOne, setStepOne] = useState([]);
    const [stepTwo, setStepTwo] = useState([]);
    const [stepThree, setStepThree] = useState([]);
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        db.collection("Sequence").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").onSnapshot(snapshot => {
            setStepOne(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").onSnapshot(snapshot => {
            setStepTwo(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").onSnapshot(snapshot => {
            setStepThree(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-five" ref={ref}>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <h1 className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                    }
                    if (fontVersion === "tablet") {
                        return <h1 className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                    }
                    if (fontVersion === "mobile") {
                        return <h1 className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                    }
                })}
                <div className="drs-five-steps">
                    {stepOne.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay1" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">1</span> <BusinessCenterIcon sx={{ fontSize: 30 }} /></span>
                                {fontVersion === "desktop" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "tablet" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "mobile" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                                }
                            </div>
                            {fontVersion === "desktop" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "tablet" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "mobile" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                            }
                        </div>
                    })}
                    {stepTwo.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay2" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">2</span> <AutoDeleteIcon sx={{ fontSize: 30 }} /></span>
                                {fontVersion === "desktop" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "tablet" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "mobile" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                                }
                            </div>
                            {fontVersion === "desktop" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "tablet" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "mobile" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                            }
                        </div>
                    })}
                    {stepThree.map((step, index) => {
                        return <div key={index} className={`drs-five-step ${inView ? "fade-up-delay3" : ""}`}>
                            <div className="drs-five-step-top">
                                <span><span className="step-num">3</span> <DoneIcon sx={{ fontSize: 30 }} /></span>
                                {fontVersion === "desktop" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "tablet" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                                }
                                {fontVersion === "mobile" &&
                                    <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                                }
                            </div>
                            {fontVersion === "desktop" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "tablet" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                            }
                            {fontVersion === "mobile" &&
                                <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                            }
                        </div>
                    })}
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionFive