import React, { useEffect, useRef, useState } from 'react'
import "../styles/DeleteReviewsSectionOne.css"
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useInView } from "react-intersection-observer";
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { db } from '../firebase';
import axios from 'axios';

const DeleteReviewsSectionOne = ({ refer }) => {

    const [selectedOption, setSelectedOption] = useState("")
    const [successMessage, setSuccessMessage] = useState(false)
    const [title, setTitle] = useState([])
    const [textList, setTextList] = useState([])
    const [inputs, setInputs] = useState([])
    const [inputOptions, setInputOptions] = useState([])
    const [dropdown, setDropdown] = useState([])
    const [loading, setLoading] = useState(false)
    const [successfullMessage, setSuccessfullMessage] = useState([])
    const { ref, inView } = useInView({ triggerOnce: true });
    const [reason, setReason] = useState("Löschgrund")
    const [isOpen, setIsOpen] = useState(false);
    const [openForm, setOpenForm] = useState(false)
    const [next, setNext] = useState(false)

    const [fontVersion, setFontVersion] = useState("")

    const [individualArr, setIndividualArr] = useState([])
    const [individualName, setIndividualName] = useState("")
    const [individualReview, setIndividualReview] = useState("")
    const [count, setCount] = useState(1)
    const addMoreIndividual = () => {
        setNext(true)
        individualArr.push({
            id: count,
            name: individualName,
            review: individualReview
        })
        setCount(count + 1)
    }

    const handleSelectedOption = (e) => {
        setSelectedOption(e.target.value);
    }

    const handleSelect = (e) => {
        setReason(e)
        setIsOpen(false)
    }

    const handleSelectMob = (e) => {
        setReason(e)
        setIsOpenMob(false)
    }

    const form = useRef();
    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref8 = useRef()
    const ref9 = useRef()

    const [enterEmail, setEnterEmail] = useState("")
    const [enterMessage, setEnterMessage] = useState("")
    const [enterPhone, setEnterPhone] = useState("")
    const [enterName, setEnterName] = useState("")
    const [enterCompanyName, setEnterCompanyName] = useState("")

    const sendEmail = async (e) => {
        e.preventDefault();

        setLoading(true)

        const email = enterEmail
        const message = enterMessage
        const name = enterName
        const company = enterCompanyName
        const phone = enterPhone
        const option = selectedOption

        let individuals = individualArr.map(individual => {
            return `<p>${individual.id}, ${individual.name}, ${individual.review}</p>`;
        }).join('');


        const reasonWhy = reason

        const subjectRec = `${name}, from ${company}`
        const subject = "Deine Anfrage ist bei uns eingegangen! 🚀"

        try {
            const response = await axios.post(`${process.env.REACT_APP_EMAIL_API_RECEIVE}`, {
                subjectRec,
                message,
                email,
                phone,
                name,
                company,
                reasonWhy,
                individuals,
                option
            });
            setSuccessMessage(true)
            ref1.current.value = ""
            ref2.current.value = ""
            ref3.current.value = ""
            ref4.current.value = ""
            ref5.current.value = ""
            ref6.current.checked = false
            setReason("Löschgrund")
            setSelectedOption("")
            setIndividualArr([])
            setLoading(false)
        } catch (error) {
            alert('Failed to send email.');
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_EMAIL_API_SEND}`, {
                email,
                subject,
                message,
            });
        } catch (error) {
            alert('Failed to send email.');
        }
    };


    const [isOpenMob, setIsOpenMob] = useState(false)
    const [info, setInfo] = useState("")
    const [openInfo, setOpenInfo] = useState(false)
    const [mobileNav, setMobileNav] = useState(null)

    const handleOpenInfo = (e) => {
        setInfo(e)
        setOpenInfo(!openInfo)
    }


    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth < 721) {
                setMobileNav(true)
            } else {
                setMobileNav(false)
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    useEffect(() => {
        db.collection("Home").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").onSnapshot(snapshot => {
            setTextList(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").onSnapshot(snapshot => {
            setInputs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputOptions").orderBy("id", "asc").onSnapshot(snapshot => {
            setInputOptions(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("dropdown").orderBy("id", "asc").onSnapshot(snapshot => {
            setDropdown(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection('Home').doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").onSnapshot(snapshot => {
            setSuccessfullMessage(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        if (selectedOption === "individual")
            setOpenForm(true)
    }, [selectedOption])

    useEffect(() => {
        if (openForm)
            document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [openForm])

    useEffect(() => {
        if (isOpenMob === true) {
            document.body.style.overflow = "hidden"
        }
        else if (isOpenMob === false) {
            document.body.style.overflow = "auto"
        }
    }, [isOpenMob])

    useEffect(() => {
        if (window.outerWidth > 1200) {
            setFontVersion("desktop")
        }
        if (window.outerWidth < 1200 && window.outerWidth > 721) {
            setFontVersion("tablet")
        }
        if (window.outerWidth < 721) {
            setFontVersion("mobile")
        }

        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-one" ref={ref}>
                <div className={`drs-one-left ${inView ? "fade-right" : ""}`}>
                    {title.map((title, index) => {
                        if (fontVersion === "desktop") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "tablet") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "mobile") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                        }
                    })}
                    <ul>
                        {textList.map((text, index) => {
                            if (fontVersion === "desktop") {
                                return <li key={index} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSize}px` }}>{text.data.text}</li>
                            }
                            if (fontVersion === "tablet") {
                                return <li key={index} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMedium}px` }}>{text.data.text}</li>
                            }
                            if (fontVersion === "mobile") {
                                return <li key={index} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMobile}px` }}>{text.data.text}</li>
                            }
                        })}
                    </ul>
                </div>
                <div className={`drs-one-right ${inView ? "fade-left" : ""}`}>
                    <form className="drs-one-right-form" ref={form} onSubmit={sendEmail}>
                        {inputs.map((input, index) => {
                            if (input.data.id === "companyName")
                                return <input key={index} ref={ref1} type="text" id="company-name" name="company-name" placeholder={input.data.placeholder} required onChange={(e) => setEnterCompanyName(e.target.value)} />
                        })}
                        {inputs.map((input, index) => {
                            if (input.data.id === "name")
                                return <input key={index} ref={ref2} type="text" id="name" name="name" placeholder={input.data.placeholder} required onChange={(e) => setEnterName(e.target.value)} />
                        })}
                        {title.map((title, index) => {
                            return <select id="review-type" name="review-type" value={selectedOption} onChange={handleSelectedOption} required>
                                <option key={index} value="" disabled>Welche Bewertungen möchtest Du löschen?</option>
                                {inputOptions.map((inputOption, index) => {
                                    return <option key={index} value={inputOption.data.value}>{inputOption.data.text}</option>
                                })}
                            </select>
                        })}
                        {openForm &&
                            <div className="individual-form add-individual">
                                <div className="ind-form-wrap">
                                    <div className="ind-wrap">
                                        {individualArr.map((individual, index) => {
                                            return <div className="inds inds-ind" key={index}>
                                                <span>{individual.id}. {individual.name}</span>
                                                <p>{individual.review}</p>
                                            </div>
                                        })}
                                    </div>
                                    {!next ? <>
                                        <div className="close-individual-form" onClick={() => setOpenForm(false)}>X</div>
                                        {inputs.map((input, index) => {
                                            if (input.data.id === "individualName")
                                                return <input key={index} ref={ref8} type="text" id="name-of-person" name="name-of-person" placeholder={input.data.placeholder} onChange={(e) => setIndividualName(e.target.value)} />
                                        })}
                                        {inputs.map((input, index) => {
                                            if (input.data.id === "individualReview")
                                                return <input key={index} ref={ref9} type="text" id="review" name="review" placeholder={input.data.placeholder} onChange={(e) => setIndividualReview(e.target.value)} />
                                        })}
                                        <div className="ind-next-button"><button className={individualName === "" || individualReview === "" ? "disabled-button-next" : "apply-button ifb"} onClick={() => addMoreIndividual()}>Nächste</button></div>
                                    </> :
                                        <div className="individual-form-buttons">
                                            <div className='apply-button' onClick={() => setNext(false)}><span className='ifb'>Mehr hinzufügen</span></div>
                                            <div className='apply-button' onClick={() => setOpenForm(false)}><span className='ifb'>Weitermachen</span></div>
                                        </div>}
                                </div>
                            </div>
                        }
                        {individualArr.map((individual, index) => {
                            return <div className="inds" key={index}>
                                <span>{individual.id}. {individual.name}</span>
                                <p>{individual.review}</p>
                            </div>
                        })}
                        {inputs.map((input, index) => {
                            if (input.data.id === "email")
                                return <input key={index} ref={ref3} type="email" id="email" name="email" placeholder={input.data.placeholder} required onChange={(e) => setEnterEmail(e.target.value)} />
                        })}
                        {inputs.map((input, index) => {
                            if (input.data.id === "phone")
                                return <input key={index} ref={ref4} type="tel" id="phone" name="phone" placeholder={input.data.placeholder} required onChange={(e) => setEnterPhone(e.target.value)} />
                        })}
                        {mobileNav ?
                            <div className="dropdown-reason-mob">
                                <div className="dropdown-select" onClick={() => setIsOpenMob(true)}><span>{reason}</span><i className="fa fa-angle-down" style={{ color: "black", fontSize: "0.750rem", fontWeight: "bold" }}></i></div>
                                {isOpenMob &&
                                    <div className="dropdown-box-mob">
                                        <ul className="dropdown-mob-list">
                                            {title.map((title, index) => {
                                                return <li key={index}><div className="dd-select"><span>{reason}</span><input type="radio" name="" id="" checked={reason === "Löschgrund" ? true : false} disabled /></div></li>
                                            })}
                                            {dropdown.map((dropdownOpt, index) => {
                                                return <li key={index}><div className="dd-info" onClick={() => handleOpenInfo(dropdownOpt.data.title)} ><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} />{openInfo && info === dropdownOpt.data.title ? <span className="dd-desc">{dropdownOpt.data.description}</span> : ""}</div><div className="dd-select" onClick={() => handleSelectMob(dropdownOpt.data.title)}><span>{dropdownOpt.data.title}</span><input type="radio" name="" id="" checked={reason === dropdownOpt.data.title ? true : false} /></div></li>
                                            })}
                                        </ul>
                                    </div>}
                            </div> :
                            <div className="dropdown-reason">
                                {title.map((title, index) => {
                                    return <div key={index} className="dropdown-select" onClick={() => setIsOpen(!isOpen)}><span>{reason}</span><i className="fa fa-angle-down" style={{ color: "black", fontSize: "0.750rem", fontWeight: "bold" }}></i></div>
                                })}
                                {isOpen &&
                                    <div className="dropdown-box">
                                        <ul>
                                            {dropdown.map((dropdownOpt, index) => {
                                                return <li key={index} onClick={() => handleSelect(dropdownOpt.data.title)}><Tooltip title={<span style={{ fontSize: "1rem" }}>{dropdownOpt.data.description}</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>{dropdownOpt.data.title}</span></li>
                                            })}
                                        </ul>
                                    </div>}
                            </div>
                        }
                        {inputs.map((input, index) => {
                            if (input.data.id === "message")
                                return <textarea key={index} ref={ref5} type="text" id="message" name="message" rows="5" placeholder={input.data.placeholder} onChange={(e) => setEnterMessage(e.target.value)} required></textarea>
                        })}
                        {title.map((inputMsg, index) => {
                            return <p key={index} style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont }}>{inputMsg.data.inputMessage}</p>
                        })}
                        {title.map((inputChbMsg, index) => {
                            return <div className="checkbox-form">
                                <input ref={ref6} type="checkbox" className="checkbox-custom" defaultChecked={false} required />
                                <label key={index} style={{ color: inputChbMsg.data.inputMessageColor, fontFamily: inputChbMsg.data.inputMessageFont }}>{inputChbMsg.data.inputCheckboxMessage}</label>
                            </div>
                        })}
                        {successMessage &&
                            <>
                                {successfullMessage.map((sucMsg, index) => {
                                    if (fontVersion === "desktop") {
                                        return <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSize}px` }}><span>{sucMsg.data.text}</span></div>
                                    }
                                    if (fontVersion === "tablet") {
                                        return <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSizeMedium}px` }}><span>{sucMsg.data.text}</span></div>
                                    }
                                    if (fontVersion === "mobile") {
                                        return <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSizeMobile}px` }}><span>{sucMsg.data.text}</span></div>
                                    }
                                })
                                }
                            </>
                        }
                        {title.map((button, index) => {
                            return <button className={loading ? "disabled-button" : "send-button"} key={index} type="submit" value="Send">{loading ? "Wird geladen..." : button.data.buttonText}</button>
                        })}
                    </form>
                </div>
            </div >
            <div className="line"></div>
        </section >
    )
}

export default DeleteReviewsSectionOne