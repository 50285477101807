import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AddNewFont.css"
import { db } from '../../firebase'
import ReactFontLoader from 'react-font-loader'

const AddNewFont = () => {

    const [openAddNewFont, setOpenAddNewFont] = useState(false)
    const [allFonts, setAllFonts] = useState([])
    const [myFonts, setMyFonts] = useState([])
    const [testFont, setTestFont] = useState("")
    const [activeNav, setActiveNav] = useState("allFonts")
    const [displayCount, setDisplayCount] = useState(150)
    const [selectedFont, setSelectedFont] = useState("")
    const [searchFont, setSearchFont] = useState("")
    const [mySelectedFontID, setMySelectedFontID] = useState("")

    const containerRef = useRef()

    const handleScroll = () => {
        if (containerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 5) {
                setDisplayCount((prevCount) => Math.min(prevCount + 150, allFonts.length));
            }
        }
    };

    const addToMyFonts = () => {
        db.collection("Fonts").add({
            name: selectedFont,
            url: `https://fonts.googleapis.com/css2?family=${selectedFont}`
        })
    }

    const removeMyFont = (id) => {
        db.collection("Fonts").doc(id).delete()
    }

    useEffect(() => {
        const container = containerRef.current;

        if (container) {
            container.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener('scroll', handleScroll);
            }
        };
    }, [openAddNewFont]);

    useEffect(() => {
        const fetchFonts = async () => {
            try {
                const response = await fetch(
                    `https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}`
                );
                const data = await response.json();
                setAllFonts(data.items);
            } catch (error) {
                console.error('Error fetching fonts:', error);
            }
        };

        fetchFonts();
    }, [])

    useEffect(() => {
        db.collection("Fonts").orderBy("name", "asc").onSnapshot(snapshot => {
            setMyFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    return (
        <div className="add-new-font">
            {allFonts.map((font, index) => {
                return <ReactFontLoader url={`https://fonts.googleapis.com/css2?family=${font.family}`} key={index} />
            })}
            <button onClick={() => setOpenAddNewFont(true)}>Add new font</button>
            {openAddNewFont && <div className='add-new-font-menu'>
                <div className="add-new-font-window">
                    <div className="add-new-font-nav">
                        <button className="close-font-menu" onClick={() => setOpenAddNewFont(false)}>X</button>
                        <ul>
                            <li className={activeNav === "allFonts" ? "active-font-nav" : ""} onClick={() => setActiveNav("allFonts")}>Select font</li>
                            <li className={activeNav === "myFonts" ? "active-font-nav" : ""} onClick={() => setActiveNav("myFonts")}>My fonts</li>
                        </ul>
                    </div>
                    {activeNav === "allFonts" && <div className="add-new-font-body">
                        <div className="display-all-fonts">
                            <div className="search-font">
                                <input type="search" name="" id="" onChange={(e) => setSearchFont(e.target.value)} placeholder='Search font' />
                            </div>
                            <div className="all-fonts" ref={containerRef}>
                                {allFonts.filter((font) =>
                                    font.family.toLowerCase().includes(searchFont.toLowerCase())
                                ).slice(0, displayCount).map((font, index) => {
                                    return <span key={index} style={{ fontFamily: font.family }} onClick={() => setSelectedFont(font.family)}>{font.family}</span>
                                })}
                            </div>
                        </div>
                        <div className="test-font">
                            <span>Selected font: {selectedFont}</span>
                            <input type="text" name="" id="" placeholder='Enter text here to try font' onChange={(e) => setTestFont(e.target.value)} />
                            <p style={{ fontFamily: selectedFont }}>{selectedFont === "" ? "This will display font" : testFont}</p>
                            <button onClick={addToMyFonts}>Add this font to my fonts</button>
                        </div>
                    </div>}
                    {activeNav === "myFonts" &&
                        <div className="my-fonts">
                            <div className="select-my-font">
                                {myFonts.map((font, index) => {
                                    return <div className={font.id === mySelectedFontID ? "select-font slctd" : "select-font"} style={{ fontFamily: font.data.name }} key={index} onClick={() => setMySelectedFontID(font.id)}>{font.data.name}</div>
                                })}
                            </div>
                            <button className="remove-my-font" onClick={() => removeMyFont(mySelectedFontID)}>Remove my font</button>
                        </div>
                    }
                </div>
            </div>}
        </div>
    )
}

export default AddNewFont