import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionSeven.css"
import DRSQNA from './DRSQNA'
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionSeven = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([]);
    const [qna, setQna] = useState([])
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        db.collection("FAQ").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("FAQ").doc("yNBnQhnXYWfGFF9aEJMV").collection("qna").onSnapshot(snapshot => {
            setQna(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-seven" ref={ref}>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <div key={index} className={`drs-seven-title ${inView ? "fade-left" : ""}`}>
                            <span style={{ color: title.data.subtitleColor, fontFamily: title.data.subtitleFont, fontSize: `${title.data.subtitleFontSize}px` }}>{title.data.subtitle}</span>
                            <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }}>{title.data.title}</h1>
                        </div>
                    }
                    if (fontVersion === "tablet") {
                        return <div key={index} className={`drs-seven-title ${inView ? "fade-left" : ""}`}>
                            <span style={{ color: title.data.subtitleColor, fontFamily: title.data.subtitleFont, fontSize: `${title.data.subtitleFontSizeMedium}px` }}>{title.data.subtitle}</span>
                            <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }}>{title.data.title}</h1>
                        </div>
                    }
                    if (fontVersion === "mobile") {
                        return <div key={index} className={`drs-seven-title ${inView ? "fade-left" : ""}`}>
                            <span style={{ color: title.data.subtitleColor, fontFamily: title.data.subtitleFont, fontSize: `${title.data.subtitleFontSizeMobile}px` }}>{title.data.subtitle}</span>
                            <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }}>{title.data.title}</h1>
                        </div>
                    }
                })}
                <div className={`drs-seven-qna ${inView ? "fade-left" : ""}`}>
                    {qna.map((qna, index) => {
                        return <DRSQNA q={qna.data.question} a={qna.data.answer} key={index} qColor={qna.data.questionColor} aColor={qna.data.answerColor} qFont={qna.data.questionFont} aFont={qna.data.answerFont} qFontSize={qna.data.questionFontSize} qFontSizeMedium={qna.data.questionFontSizeMedium} qFontSizeMobile={qna.data.questionFontSizeMobile} aFontSize={qna.data.answerFontSize} aFontSizeMedium={qna.data.answerFontSize} aFontSizeMobile={qna.data.answerFontSizeMobile} />
                    })}
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionSeven