import React, { useEffect, useState } from 'react'
import "../styles/DeleteReviewsSectionSix.css"
import DRSCarousel from './DRSCarousel'
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionSix = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([])
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        db.collection("Feedback").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <section ref={refer}>
            <div className="delete-reviews-section-six" ref={ref}>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <h3 className={inView ? "fade-right" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h3>
                    }
                    if (fontVersion === "tablet") {
                        return <h3 className={inView ? "fade-right" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h3>
                    }
                    if (fontVersion === "mobile") {
                        return <h3 className={inView ? "fade-right" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h3>
                    }
                })}
                <div className={`drs-six-comments ${inView ? "fade-left" : ""}`}>
                    <DRSCarousel />
                </div>
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionSix