import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionFour.css"
import { db } from '../../firebase';

const AdminSectionFour = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeFontSize, setChangeFontSize] = useState("")
    const [packs, setPacks] = useState([])
    const [changeTextColor, setChangeTextColor] = useState("")
    const [changeBackgroundColor, setChangeBackgroundColor] = useState("")
    const [changePackName, setChangePackName] = useState("")
    const [changeRevNumb, setChangeRevNumb] = useState("")
    const [changePrice, setChangePrice] = useState("")
    const [changeOldPrice, setChangeOldPrice] = useState("")
    const [changeSavings, setChangeSavings] = useState("")
    const [changeDescription, setChangeDescription] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeTextFont, setChangeTextFont] = useState("")
    const [fontVersion, setFontVersion] = useState("desktop")

    const ref1 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
            titleFont: changeTitleFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
                fontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
                fontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").update({
                fontSizeMobile: changeFontSize
            })
        }
    }

    const applyTextFont = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            textFont: changeTextFont
        })
    }

    const applyTextColor = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            textColor: changeTextColor
        })
    }

    const applyBackgroundColor = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            backgroundColor: changeBackgroundColor
        })
    }

    const applyPackName = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            packName: changePackName,
        })
    }

    const applyPackNameFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packNameFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packNameFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packNameFontSizeMobile: changeFontSize
            })
        }
    }

    const applyRevNumb = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            revNumb: changeRevNumb,
        })
    }

    const applyPrice = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            price: changePrice,
        })
    }

    const applyPackPriceFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packPriceFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packPriceFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
                packPriceFontSizeMobile: changeFontSize
            })
        }
    }

    const applyOldPrice = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            oldPrice: changeOldPrice,
        })
    }

    const applySavings = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            savings: changeSavings,
        })
    }

    const applyDescription = (id) => {
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").doc(id).update({
            description: changeDescription
        })
    }

    useEffect(() => {
        db.collection("Price").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Price").doc("KnFeLPF6mEgQXVcArIOL").collection("price-packets").onSnapshot(snapshot => {
            setPacks(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="delete-reviews-section-four">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Price")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="pricing-table">
                <div className="change-title-price">
                    <div className="change-font">
                        <div className="change-font-family">
                            <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                        </div>
                        <div className="change-font-size">
                            <span>Font size<p>{fontVersion}: </p></span>
                            {title.map((fontSize, index) => {
                                if (fontVersion === "desktop") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "tablet") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "mobile") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                            })}
                            <button className="apply-button" onClick={applyFontSize}>Apply</button>
                        </div>
                    </div>
                    <div className="change-color">
                        <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                        <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                    </div>
                    <label>Change title to:</label>
                    <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                    <button className="apply-button" onClick={applyTitle}>Apply</button>
                    {title.map((title, index) => {
                        if (fontVersion === "desktop") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "tablet") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "mobile") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                        }
                    })}
                </div>
                <div className="packages">
                    {packs.map((pack, index) => {
                        return <div className="admin-package" key={index}>
                            <div className="change-font" style={{ flexDirection: "column" }}>
                                <div className="change-font-family">
                                    <select name="" id="" onChange={(e) => setChangeTextFont(e.target.value)}>
                                        {fonts.map((font, index) => {
                                            return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                        })}
                                    </select>
                                    <button className="apply-button" onClick={() => applyTextFont(pack.id)}>Apply</button>
                                </div>
                                <div className="change-font-size">
                                    <label style={{ fontSize: "0.9rem" }}>Packet name font size <p style={{ fontSize: "1.2rem" }}>{fontVersion} version:</p></label>
                                    {fontVersion === "desktop" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packNameFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "tablet" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packNameFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "mobile" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packNameFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    <button className="apply-button" onClick={() => applyPackNameFontSize(pack.id)}>Apply</button>
                                </div>
                                <div className="change-font-size">
                                    <label style={{ fontSize: "0.9rem" }}>Packet price font size<p style={{ fontSize: "1.2rem" }}>{fontVersion} version:</p></label>
                                    {fontVersion === "desktop" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packPriceFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "tablet" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packPriceFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "mobile" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={pack.data.packPriceFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    <button className="apply-button" onClick={() => applyPackPriceFontSize(pack.id)}>Apply</button>
                                </div>
                            </div>
                            <div className="package-inputs">
                                <div className="change-color">
                                    <label>Change text color</label>
                                    <input type="color" defaultValue="#000" onChange={(e) => setChangeTextColor(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyTextColor(pack.id)}>Apply</button>
                                </div>
                                <div className="change-color">
                                    <label>Change background color</label>
                                    <input type="color" defaultValue={pack.data.backgroundColor} onChange={(e) => setChangeBackgroundColor(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyBackgroundColor(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="text" placeholder="Enter pack name" onChange={(e) => setChangePackName(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyPackName(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="number" placeholder="Enter number or reviews" onChange={(e) => setChangeRevNumb(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyRevNumb(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="number" placeholder="Enter price" onChange={(e) => setChangePrice(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyPrice(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="number" placeholder="Enter old price" onChange={(e) => setChangeOldPrice(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyOldPrice(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="number" placeholder="Enter savings" onChange={(e) => setChangeSavings(e.target.value)} />
                                    <button className="apply-button" onClick={() => applySavings(pack.id)}>Apply</button>
                                </div>
                                <div className="package-input">
                                    <input type="text" placeholder="Packet description" onChange={(e) => setChangeDescription(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyDescription(pack.id)}>Apply</button>
                                </div>
                            </div>
                            {fontVersion === "desktop" &&
                                <div style={{ backgroundColor: pack.data.backgroundColor, color: pack.data.textColor }} className="package">
                                    <h2 style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packNameFontSize}px` }}>{pack.data.packName}</h2>
                                    <span style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }}>{pack.data.revNumb} Bewertungen für nur</span>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packPriceFontSize}px` }} className="price">{pack.data.price}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="old-price">war {pack.data.oldPrice}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="savings">{pack.data.savings}% ersparnis</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="description">{pack.data.description}</p>
                                </div>}
                            {fontVersion === "tablet" &&
                                <div style={{ backgroundColor: pack.data.backgroundColor, color: pack.data.textColor }} className="package">
                                    <h2 style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packNameFontSizeMedium}px` }}>{pack.data.packName}</h2>
                                    <span style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }}>{pack.data.revNumb} Bewertungen für nur</span>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packPriceFontSizeMedium}px` }} className="price">{pack.data.price}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="old-price">war {pack.data.oldPrice}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="savings">{pack.data.savings}% ersparnis</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="description">{pack.data.description}</p>
                                </div>}
                            {fontVersion === "mobile" &&
                                <div style={{ backgroundColor: pack.data.backgroundColor, color: pack.data.textColor }} className="package">
                                    <h2 style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packNameFontSizeMobile}px` }}>{pack.data.packName}</h2>
                                    <span style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }}>{pack.data.revNumb} Bewertungen für nur</span>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont, fontSize: `${pack.data.packPriceFontSizeMobile}px` }} className="price">{pack.data.price}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="old-price">war {pack.data.oldPrice}€</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="savings">{pack.data.savings}% ersparnis</p>
                                    <p style={{ color: pack.data.textColor, fontFamily: pack.data.textFont }} className="description">{pack.data.description}</p>
                                </div>}
                        </div>
                    })}
                </div>
                <div class="bulk-discount">
                    <p>Sonderpreise bei großen Mengen möglich</p>
                    <a>Kontaktiere uns</a>
                </div>
            </div>
        </div>
    )
}

export default AdminSectionFour