import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminFooter.css"
import { db } from '../../firebase';

const AdminFooter = () => {

    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeEmail, setChangeEmail] = useState("")
    const [changeEmailColor, setChangeEmailColor] = useState("")
    const [changeEmailFont, setChangeEmailFont] = useState("")
    const [changePhone, setChangePhone] = useState("")
    const [changePhoneColor, setChangePhoneColor] = useState("")
    const [changePhoneFont, setChangePhoneFont] = useState("")
    const [fonts, setFonts] = useState([])
    const [fontVersion, setFontVersion] = useState()
    const [changeFontSize, setChangeFontSize] = useState("")
    const [footerText, setFooterText] = useState([])
    const [changeFooterTextFont, setChangeFooterTextFont] = useState("")
    const [changeFooterTextColor, setChangeFooterTextColor] = useState("")
    const [changeText, setChangeText] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()

    const applyFooterTextFont = (id) => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
            textFont: changeFooterTextFont
        })
    }

    const applyFooterTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
                textFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
                textFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
                textFontSizeMobile: changeFontSize
            })
        }
    }

    const applyFooterTextColor = (id) => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
            textColor: changeFooterTextColor
        })
    }

    const applyText = (id) => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").doc(id).update({
            text: changeText
        })
    }

    const applyTitle = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            titleFont: changeTitleFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                titleFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                titleFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                titleFontSizeMobile: changeFontSize
            })
        }
    }

    const applyEmail = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            email: changeEmail
        })
        ref2.current.value = ""
    }

    const applyEmailColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            emailColor: changeEmailColor
        })
    }

    const applyEmailFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            emailFont: changeEmailFont
        })
    }

    const applyEmailFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                emailFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                emailFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                emailFontSizeMobile: changeFontSize
            })
        }
    }

    const applyPhone = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phone: changePhone
        })
        ref3.current.value = ""
    }

    const applyPhoneColor = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phoneColor: changePhoneColor
        })
    }

    const applyPhoneFont = () => {
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
            phoneFont: changePhoneFont
        })
    }

    const applyPhoneFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                phoneFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                phoneFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").update({
                phoneFontSizeMobile: changeFontSize
            })
        }
    }

    useEffect(() => {
        db.collection('Footer').onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Footer").doc("WVf4j3717abAsnDNDCLA").collection("text").onSnapshot(snapshot => {
            setFooterText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        })
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="footer admin-footer">
            <div className="contact">
                <div className="contact-info">
                    <div className="change-footer-texts">
                        {footerText.map((text, index) => {
                            return <div className="change-footer-text" key={index}>
                                <div className="change-font">
                                    <div className="change-font-family">
                                        <select name="" id="" onChange={(e) => setChangeFooterTextFont(e.target.value)}>
                                            {fonts.map((font, index) => {
                                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                            })}
                                        </select>
                                        <button className="apply-button" onClick={() => applyFooterTextFont(text.id)}>Apply</button>
                                    </div>
                                    <div className="change-font-size">
                                        <span>Font size<p>{fontVersion}: </p></span>
                                        {fontVersion === "desktop" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "tablet" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "mobile" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        <button className="apply-button" onClick={() => applyFooterTextFontSize(text.id)}>Apply</button>
                                    </div>
                                </div>
                                <div className="change-color">
                                    <input type="color" defaultValue={text.data.textColor} onChange={(e) => setChangeFooterTextColor(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyFooterTextColor(text.id)}>Apply</button>
                                </div>
                                <div className="change-text">
                                    <label>Change text to:</label>
                                    <input type="text" onChange={(e) => setChangeText(e.target.value)} placeholder="Enter text here" />
                                    <button className="apply-button" onClick={() => applyText(text.id)}>Apply</button>
                                </div>
                                {fontVersion === "desktop" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSize}px` }}>{text.data.text}</p>}
                                {fontVersion === "tablet" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMedium}px` }}>{text.data.text}</p>}
                                {fontVersion === "mobile" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMobile}px` }}>{text.data.text}</p>}
                            </div>
                        })}
                    </div>
                    <div className="change-title-wrap admin-footer-title">
                        <div className="change-font">
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {title.map((fontSize, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.titleFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.titleFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.titleFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={applyFontSize}>Apply</button>
                            </div>
                        </div>
                        <div className="change-title">
                            <div className="change-color">
                                {title.map((titleColor, index) => {
                                    return <input type="color" key={index} defaultValue={titleColor.data.titleColor} onChange={(e) => setChangeTitleColor(e.target.value)} />
                                })}
                                <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                            </div>
                            <label>Change title to:</label>
                            <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                            <button className="apply-button" onClick={applyTitle}>Apply</button>
                        </div>
                        {title.map((title, index) => {
                            if (fontVersion === "desktop") {
                                return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.titleFontSize}px` }} key={index}>{title.data.title}</h3>
                            }
                            if (fontVersion === "tablet") {
                                return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.titleFontSizeMedium}px` }} key={index}>{title.data.title}</h3>
                            }
                            if (fontVersion === "mobile") {
                                return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.titleFontSizeMobile}px` }} key={index}>{title.data.title}</h3>
                            }
                        })}
                    </div>
                    <div className="change-info">
                        <label htmlFor="">Email font:</label>
                        <div className="change-font admin-footer-change-font">
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeEmailFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={applyEmailFont}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {title.map((fontSize, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.emailFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.emailFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.emailFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={applyEmailFontSize}>Apply</button>
                            </div>
                        </div>
                        <label htmlFor="">Phone font:</label>
                        <div className="change-font admin-footer-change-font">
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangePhoneFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={applyPhoneFont}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {title.map((fontSize, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.phoneFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.phoneFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.phoneFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={applyPhoneFontSize}>Apply</button>
                            </div>
                        </div>
                        <div className="change-title">
                            {title.map((colors, index) => {
                                return <>
                                    <div className="change-color">
                                        <label htmlFor="">Email color:</label>
                                        <input type="color" defaultValue={colors.data.emailColor} onChange={(e) => setChangeEmailColor(e.target.value)} />
                                        <button className="apply-button" onClick={applyEmailColor}>Apply</button>
                                    </div>
                                    <div className="change-color">
                                        <label htmlFor="">Phone color:</label>
                                        <input type="color" defaultValue={colors.data.phoneColor} onChange={(e) => setChangePhoneColor(e.target.value)} />
                                        <button className="apply-button" onClick={applyPhoneColor}>Apply</button>
                                    </div>
                                </>
                            })}
                            <input ref={ref2} type="text" onChange={(e) => setChangeEmail(e.target.value)} placeholder="Enter email here" />
                            <button className="apply-button" onClick={applyEmail}>Apply</button>
                            <input ref={ref3} type="text" onChange={(e) => setChangePhone(e.target.value)} placeholder="Enter phone here" />
                            <button className="apply-button" onClick={applyPhone}>Apply</button>
                        </div>
                        {title.map((title, index) => {
                            return <>
                                <div className="contact-email">
                                    {fontVersion === "desktop" && <span style={{ color: title.data.emailColor, fontFamily: title.data.emailFont, fontSize: `${title.data.emailFontSize}px` }}>{title.data.email}</span>}
                                    {fontVersion === "tablet" && <span style={{ color: title.data.emailColor, fontFamily: title.data.emailFont, fontSize: `${title.data.emailFontSizeMedium}px` }}>{title.data.email}</span>}
                                    {fontVersion === "mobile" && <span style={{ color: title.data.emailColor, fontFamily: title.data.emailFont, fontSize: `${title.data.emailFontSizeMobile}px` }}>{title.data.email}</span>}
                                </div>
                                <div className="contact-tel">
                                    {fontVersion === "desktop" && <span style={{ color: title.data.phoneColor, fontFamily: title.data.phoneFont, fontSize: `${title.data.phoneFontSize}px` }}>{title.data.phone}</span>}
                                    {fontVersion === "tablet" && <span style={{ color: title.data.phoneColor, fontFamily: title.data.phoneFont, fontSize: `${title.data.phoneFontSizeMedium}px` }}>{title.data.phone}</span>}
                                    {fontVersion === "mobile" && <span style={{ color: title.data.phoneColor, fontFamily: title.data.phoneFont, fontSize: `${title.data.phoneFontSizeMobile}px` }}>{title.data.phone}</span>}
                                </div>
                            </>
                        })}
                    </div>
                </div>
            </div>
            <div className="disclaimers">
                <div className="disclaimer">
                    <span>Keine Rechtsberatung</span>
                    <p>Die auf dieser Webseite angebotene Dienstleistung beschränkt sich ausschließlich auf eine Botentätigkeit. Wir möchten ausdrücklich darauf hinweisen, dass unsere Dienste keine Rechtsberatung beinhalten oder ersetzen. Wir leiten Ihr Anliegen lediglich gemäß Ihrer Beschreibung weiter, ohne eine inhaltliche Prüfung oder rechtliche Bewertung vorzunehmen.</p>
                </div>
                <div className="disclaimer">
                    <span>Haftungsausschluss</span>
                    <p>Wir übernehmen keine Haftung für die Richtigkeit, Vollständigkeit oder Aktualität der Informationen, die Sie uns zur Weiterleitung zur Verfügung stellen. Jegliche Haftung für direkte oder indirekte Schäden, die durch die Nutzung unserer Dienste entstehen, wird ausgeschlossen.</p>
                </div>
                <div className="disclaimer">
                    <span>Eigenverantwortung des Nutzers</span>
                    <p>Als Nutzer unserer Dienstleistung sind Sie allein dafür verantwortlich, die rechtliche Relevanz und Korrektheit Ihrer Anliegen zu überprüfen. Bei rechtlichen Fragen oder Unsicherheiten empfehlen wir Ihnen, sich an einen qualifizierten Rechtsberater zu wenden.</p>
                </div>
            </div>
        </div>
    )
}

export default AdminFooter