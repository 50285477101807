import React, { useEffect, useState } from 'react'
import "../styles/DRSQNA.css"
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const DRSQNA = ({ q, a, qColor, aColor, qFont, aFont, qFontSize, aFontSize, qFontSizeMedium, aFontSizeMedium, qFontSizeMobile, aFontSizeMobile }) => {

    const [expanded, setExpanded] = useState(false)
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="drsqna">
            <div className="drs-q" onClick={() => setExpanded(!expanded)}>
                {fontVersion === "desktop" && <span style={{ color: qColor, fontFamily: qFont, fontSize: `${qFontSize}px` }}>{q}</span>}
                {fontVersion === "tablet" && <span style={{ color: qColor, fontFamily: qFont, fontSize: `${qFontSizeMedium}px` }}>{q}</span>}
                {fontVersion === "mobile" && <span style={{ color: qColor, fontFamily: qFont, fontSize: `${qFontSizeMobile}px` }}>{q}</span>}
                {expanded ? <RemoveIcon /> : <AddIcon />}
            </div>
            {expanded && <div className="drs-a">
                {fontVersion === "desktop" && <p style={{ color: aColor, fontFamily: aFont, fontSize: `${aFontSize}px` }}>{a}</p>}
                {fontVersion === "tablet" && <p style={{ color: aColor, fontFamily: aFont, fontSize: `${aFontSizeMedium}px` }}>{a}</p>}
                {fontVersion === "mobile" && <p style={{ color: aColor, fontFamily: aFont, fontSize: `${aFontSizeMobile}px` }}>{a}</p>}
            </div>
            }
        </div>
    )
}

export default DRSQNA