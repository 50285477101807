import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionThree.css"
import { db } from '../../firebase';

const AdminSectionThree = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [text, setText] = useState([])
    const [changeText, setChangeText] = useState("")
    const [changeTextColor, setChangeTextColor] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeFontSize, setChangeFontSize] = useState("")
    const [changeTextFont, setChangeTextFont] = useState("")
    const [fontVersion, setFontVersion] = useState("desktop")

    const ref1 = useRef()
    const ref2 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
            titleFont: changeTitleFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
                fontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
                fontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").update({
                fontSizeMobile: changeFontSize
            })
        }
    }

    const applyTextFont = (id) => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).update({
            textFont: changeTextFont
        })
    }

    const applyTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).update({
                textFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).update({
                textFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).update({
                textFontSizeMobile: changeFontSize
            })
        }
    }

    const applyText = (id) => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).set({
            text: changeText
        })
        ref2.current.value = ""
    }

    const applyTextColor = (id) => {
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").doc(id).update({
            textColor: changeTextColor
        })
    }

    useEffect(() => {
        db.collection("Service").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Service").doc("gC3qv8dp0VPIh2NWeymv").collection("text").onSnapshot(snapshot => {
            setText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="delete-reviews-section-three">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Service")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="drs-three-title">
                <div className="change-title-wrap-service">
                    <div className="change-font">
                        <div className="change-font-family">
                            <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                                {fonts.map((font, index) => {
                                    return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                })}
                            </select>
                            <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                        </div>
                        <div className="change-font-size">
                            <span>Font size<p>{fontVersion}: </p></span>
                            {title.map((fontSize, index) => {
                                if (fontVersion === "desktop") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "tablet") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "mobile") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                            })}
                            <button className="apply-button" onClick={applyFontSize}>Apply</button>
                        </div>
                    </div>
                    <div className="change-title">
                        <div className="change-color">
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                        </div>
                        <label>Change title to:</label>
                        <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                        <button className="apply-button" onClick={applyTitle}>Apply</button>
                    </div>
                    {title.map((title, index) => {
                        if (fontVersion === "desktop") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "tablet") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "mobile") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                        }
                    })}
                </div>
                {text.map((text, index) => {
                    return <div className="change-text-service" key={index}>
                        <div className="change-font">
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeTextFont(e.target.value)}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={() => applyTextFont(text.id)}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {fontVersion === "desktop" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                {fontVersion === "tablet" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                {fontVersion === "mobile" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                <button className="apply-button" onClick={() => applyTextFontSize(text.id)}>Apply</button>
                            </div>
                        </div>
                        <div className="change-color">
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyTextColor(text.id)}>Apply</button>
                        </div>
                        <label>Change text to:</label>
                        <textarea ref={ref2} rows="4" type="text" onChange={(e) => setChangeText(e.target.value)} placeholder="Enter text here" />
                        <button className="apply-button" onClick={() => applyText(text.id)}>Apply</button>
                        {fontVersion === "desktop" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSize}px` }} className="admin-service-text">{text.data.text}</p>}
                        {fontVersion === "tablet" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMedium}px` }} className="admin-service-text">{text.data.text}</p>}
                        {fontVersion === "mobile" && <p style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMobile}px` }} className="admin-service-text">{text.data.text}</p>}
                    </div>
                })}
            </div>
            <div className="drs-three-img">
                <img src="./img/Bewertungen-tel.webp" alt="" />
            </div>
        </div>
    )
}

export default AdminSectionThree