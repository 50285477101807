import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionSix.css"
import { db } from '../../firebase';
import AdminDRSCarousel from './AdminDRSCarousel';

const AdminSectionSix = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [changeFontSize, setChangeFontSize] = useState("")
    const [fontVersion, setFontVersion] = useState("desktop")

    const ref1 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
            titleFont: changeTitleFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
                fontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
                fontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").update({
                fontSizeMobile: changeFontSize
            })
        }
    }

    useEffect(() => {
        db.collection("Feedback").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="delete-reviews-section-six">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Feedback")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="admin-section-six-title">
                <div className="change-font">
                    <div className="change-font-family">
                        <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                            {fonts.map((font, index) => {
                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                            })}
                        </select>
                        <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                    </div>
                    <div className="change-font-size">
                        <span>Font size<p>{fontVersion}: </p></span>
                        {title.map((fontSize, index) => {
                            if (fontVersion === "desktop") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                            if (fontVersion === "tablet") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                            if (fontVersion === "mobile") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                        })}
                        <button className="apply-button" onClick={applyFontSize}>Apply</button>
                    </div>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                    <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                </div>
                <label>Change title to:</label>
                <input ref={ref1} type="text" placeholder="Enter title here" onChange={(e) => setChangeTitle(e.target.value)} />
                <button className="apply-button" onClick={applyTitle}>Apply</button>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h3>
                    }
                    if (fontVersion === "tablet") {
                        return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h3>
                    }
                    if (fontVersion === "mobile") {
                        return <h3 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h3>
                    }
                })}
            </div>
            <div className="drs-six-comments">
                <AdminDRSCarousel />
            </div>
        </div>
    )
}

export default AdminSectionSix