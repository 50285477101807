import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import "../styles/DRSCarousel.css"
import StarRatings from 'react-star-ratings';
import { db } from '../firebase';


const DRSCarousel = () => {

    const [comments, setComments] = useState([])
    const [settings, setSettings] = useState()
    const [starSize, setStarSize] = useState("")
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        db.collection("Feedback").doc("l4hu8C9XuIOYdvBEALyg").collection("comments").onSnapshot(snapshot => {
            setComments(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });

        const windowWidth = () => {
            if (window.outerWidth < 561) {
                setSettings(settingsMob)
                setStarSize("25px")
            } else {
                setSettings(settingsDef)
                setStarSize("40px")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    const settingsDef = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    const settingsMob = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <div className="drs-carousel">
            <Slider {...settings}>
                {comments.map((comment, index) => (
                    <div className="drs-carousel-comment" key={index}>
                        <div className="drs-carousel-comment-content">
                            <div className="drs-carousel-comment-rating">
                                <span className="rating" style={{ color: comment.data.textColor }}>{comment.data.rating}</span>
                                <div className="rating-stars">
                                    <StarRatings rating={parseFloat(comment.data.rating)} starRatedColor="rgb(251, 188, 4)" starEmptyColor="white" starDimension={starSize} starSpacing="3px" />
                                </div>
                            </div>
                            {fontVersion === "desktop" && <p style={{ color: comment.data.textColor, fontFamily: comment.data.textFont, fontSize: `${comment.data.commentTextFontSize}px` }} className="drs-carousel-comment-text">{comment.data.text}</p>}
                            {fontVersion === "tablet" && <p style={{ color: comment.data.textColor, fontFamily: comment.data.textFont, fontSize: `${comment.data.commentTextFontSizeMedium}px` }} className="drs-carousel-comment-text">{comment.data.text}</p>}
                            {fontVersion === "mobile" && <p style={{ color: comment.data.textColor, fontFamily: comment.data.textFont, fontSize: `${comment.data.commentTextFontSizeMobile}px` }} className="drs-carousel-comment-text">{comment.data.text}</p>}
                            <div className="drs-carousel-comment-user">
                                {fontVersion === "desktop" &&
                                    <div className="drs-carousel-comment-name" style={{ fontFamily: comment.data.personFont, fontSize: `${comment.data.commentPersonFontSize}px` }}>
                                        <p>{comment.data.name}</p>
                                        <span>{comment.data.job}</span>
                                    </div>}
                                {fontVersion === "tablet" &&
                                    <div className="drs-carousel-comment-name" style={{ fontFamily: comment.data.personFont, fontSize: `${comment.data.commentPersonFontSizeMedium}px` }}>
                                        <p>{comment.data.name}</p>
                                        <span>{comment.data.job}</span>
                                    </div>}
                                {fontVersion === "mobile" &&
                                    <div className="drs-carousel-comment-name" style={{ fontFamily: comment.data.personFont, fontSize: `${comment.data.commentPersonFontSizeMobile}px` }}>
                                        <p>{comment.data.name}</p>
                                        <span>{comment.data.job}</span>
                                    </div>}
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default DRSCarousel;