import React, { useEffect, useRef, useState } from 'react'
import "../styles/DeleteReviewsSectionTwo.css"
import { useInView } from 'react-intersection-observer';
import { db } from '../firebase';

const DeleteReviewsSectionTwo = ({ refer }) => {

    const { ref, inView } = useInView({ triggerOnce: true });
    const [title, setTitle] = useState([])
    const [text, setText] = useState([])
    const [fontVersion, setFontVersion] = useState("")

    useEffect(() => {
        db.collection("AboutUs").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("AboutUs").doc("D2SOLxEM1s04s8zIhQHL").collection("text").onSnapshot(snapshot => {
            setText(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <section ref={refer}>
            <div className='delete-reviews-section-two' ref={ref}>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <h1 key={index} className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }}>{title.data.title}</h1>
                    }
                    if (fontVersion === "tablet") {
                        return <h1 key={index} className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }}>{title.data.title}</h1>
                    }
                    if (fontVersion === "mobile") {
                        return <h1 key={index} className={inView ? "fade-down" : ""} style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }}>{title.data.title}</h1>
                    }
                })}

                {text.map((text, index) => {
                    if (fontVersion === "desktop") {
                        return <p key={index} className={inView ? "fade-up" : ""} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSize}px` }}>{text.data.text}</p>
                    }
                    if (fontVersion === "tablet") {
                        return <p key={index} className={inView ? "fade-up" : ""} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMedium}px` }}>{text.data.text}</p>
                    }
                    if (fontVersion === "mobile") {
                        return <p key={index} className={inView ? "fade-up" : ""} style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMobile}px` }}>{text.data.text}</p>
                    }
                })}
            </div>
            <div className="line"></div>
        </section>
    )
}

export default DeleteReviewsSectionTwo