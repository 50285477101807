import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionOne.css"
import { db } from '../../firebase';
import ReactFontLoader from 'react-font-loader';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import AddNewFont from './AddNewFont';

const AdminSectionOne = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [textList, setTextList] = useState([])
    const [changeTextList, setChangeTextList] = useState("")
    const [changeTextColor, setChangeTextColor] = useState("")
    const [changeTextFont, setChangeTextFont] = useState("")
    const [changeFontSize, setChangeFontSize] = useState("")
    const [inputs, setInputs] = useState([])
    const [selectedOption, setSelectedOption] = useState()
    const [openForm, setOpenForm] = useState(false)
    const [next, setNext] = useState(false)
    const [changePlaceholderCompany, setChangePlaceholderCompany] = useState("")
    const [changePlaceholderName, setChangePlaceholderName] = useState("")
    const [changePlaceholderIndividualName, setChangePlaceholderIndividualName] = useState("")
    const [changePlaceholderIndividualReview, setChangePlaceholderIndividualReview] = useState("")
    const [changePlaceholderEmail, setChangePlaceholderEmail] = useState("")
    const [changePlaceholderPhone, setChangePlaceholderPhone] = useState("")
    const [changePlaceholderMessage, setChangePlaceholderMessage] = useState("")
    const [changeInputMessage, setChangeInputMessage] = useState("")
    const [changeInputCheckboxMessage, setChangeInputCheckboxMessage] = useState("")
    const [changeInputMessageFont, setChangeInputMessageFont] = useState("")
    const [changeInputMessageColor, setChangeInputMessageColor] = useState("")
    const [successfullMessage, setSuccessfullMessage] = useState([])
    const [changeSuccessMessage, setChangeSuccessMessage] = useState("")
    const [changeSuccessMessageBackground, setChangeSuccessMessageBackground] = useState("")
    const [changeMessageFont, setChangeMessageFont] = useState("")
    const [fonts, setFonts] = useState([])
    const [reason, setReason] = useState("Löschgrund")
    const [isOpen, setIsOpen] = useState(false);
    const [fontVersion, setFontVersion] = useState("")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()
    const ref8 = useRef()
    const ref9 = useRef()
    const ref12 = useRef()
    const ref13 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            titleFont: changeTitleFont
        })
    }

    const applyTextFont = (id) => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
            textFont: changeTextFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                fontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                fontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                fontSizeMobile: changeFontSize
            })
        }
    }

    const applyMessageFont = (id) => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc(id).update({
            messageFont: changeMessageFont
        })
    }

    const applyTextList = (id) => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
            text: changeTextList
        })
    }

    const applyTextColor = (id) => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
            textColor: changeTextColor
        })
    }

    const applyTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
                textFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
                textFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").doc(id).update({
                textFontSizeMobile: changeFontSize
            })
        }
    }

    const applyPlaceholderCompany = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("wHLmDp3mwbISvUAGK2L4").update({
            placeholder: changePlaceholderCompany
        })
        ref3.current.value = ""
    }

    const applyPlaceholderName = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("rmCf6yJvSy44uearh2bh").update({
            placeholder: changePlaceholderName
        })
        ref4.current.value = ""
    }

    const applyPlaceholderIndividualName = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("cxgdYKfvRad0omyuJgSZ").update({
            placeholder: changePlaceholderIndividualName
        })
        ref8.current.value = ""
    }

    const applyPlaceholderIndividualReview = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("v2hlkvdU4fY0MzR5qY55").update({
            placeholder: changePlaceholderIndividualReview
        })
        ref9.current.value = ""
    }

    const applyPlaceholderEmail = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("UNLDlwDkUVnTfzSJDbYC").update({
            placeholder: changePlaceholderEmail
        })
        ref5.current.value = ""
    }

    const applyPlaceholderPhone = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("tIFUNWg0YPSuKQvYRySu").update({
            placeholder: changePlaceholderPhone
        })
        ref6.current.value = ""
    }

    const applyPlaceholderMessage = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").doc("GXBBgXXVzHXkkqlUPRsG").update({
            placeholder: changePlaceholderMessage
        })
        ref7.current.value = ""
    }

    const applyInputMessage = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            inputMessage: changeInputMessage
        })
        ref12.current.value = ""
    }

    const applyInputCheckboxMessage = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            inputCheckboxMessage: changeInputCheckboxMessage
        })
        ref13.current.value = ""
    }

    const applyInputMessageFont = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            inputMessageFont: changeInputMessageFont
        })
    }

    const applyInputMessageColor = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
            inputMessageColor: changeInputMessageColor
        })
    }

    const applyInputMessageFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                inputMessageFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                inputMessageFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").update({
                inputMessageFontSizeMobile: changeFontSize
            })
        }
    }

    const applySuccessMessage = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc("Emf4LLtQ32z5LSrHyAUi").update({
            text: changeSuccessMessage
        })
        ref2.current.value = ""
    }

    const applySuccessMessageBackground = () => {
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc("Emf4LLtQ32z5LSrHyAUi").update({
            messageBackground: changeSuccessMessageBackground
        })
    }

    const applySuccMsgFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc(id).update({
                textSuccMsgFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc(id).update({
                textSuccMsgFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").doc(id).update({
                textSuccMsgFontSizeMobile: changeFontSize
            })
        }
    }

    const handleSelect = (e) => {
        setReason(e)
        setIsOpen(false)
    }

    useEffect(() => {
        db.collection('Home').onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("list-text").onSnapshot(snapshot => {
            setTextList(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Home").doc("5kLzpZ1IC9a8UqGNrKhB").collection("inputs").onSnapshot(snapshot => {
            setInputs(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection('Home').doc("5kLzpZ1IC9a8UqGNrKhB").collection("successfull-message").onSnapshot(snapshot => {
            setSuccessfullMessage(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").orderBy("name", "asc").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        if (window.outerWidth > 1200) {
            setFontVersion("desktop")
        }
        if (window.outerWidth < 1200 && window.outerWidth > 721) {
            setFontVersion("tablet")
        }
        if (window.outerWidth < 721) {
            setFontVersion("mobile")
        }

        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    useEffect(() => {
        if (selectedOption === "individual")
            setOpenForm(true)
    }, [selectedOption])

    useEffect(() => {
        if (openForm)
            document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'auto';
    }, [openForm])

    return (
        <div className="delete-reviews-section-one">
            {fonts.map((font, index) => {
                return <ReactFontLoader url={`${font.data.url}`} key={index} />
            })}
            {order.map((order, index) => {
                if (order.data.pageTitle === "Home")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <AddNewFont />
            <div className="admin-drs-one-left">
                <div className="change-title-wrap">
                    <div className="change-font">
                        {title.map((fontFamily, index) => {
                            return <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)} defaultValue={fontFamily.data.titleFont}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                            </div>
                        })}
                        <div className="change-font-size">
                            <span>Font size<p>{fontVersion}: </p></span>
                            {title.map((fontSize, index) => {
                                if (fontVersion === "desktop") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "tablet") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                                if (fontVersion === "mobile") {
                                    return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                }
                            })}
                            <button className="apply-button" onClick={applyFontSize}>Apply</button>
                        </div>
                    </div>
                    <div className="change-title">
                        <div className="change-color">
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                        </div>
                        <label>Change title to:</label>
                        <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                        <button className="apply-button" onClick={applyTitle}>Apply</button>
                    </div>
                    {title.map((title, index) => {
                        if (fontVersion === "desktop") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "tablet") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                        }
                        if (fontVersion === "mobile") {
                            return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                        }
                    })}
                </div>
                <ul>
                    {textList.map((text, index) => {
                        return <div className="change-text-list" key={index}>
                            <div className="change-font">
                                <div className="change-font-family">
                                    <select name="" id="" onChange={(e) => setChangeTextFont(e.target.value)} defaultValue={text.data.textFont}>
                                        {fonts.map((font, index) => {
                                            return <option key={index} style={{ fontFamily: font.data.name }} value={font.data.name}>{font.data.name}</option>
                                        })}
                                    </select>
                                    <button className="apply-button" onClick={() => applyTextFont(text.id)}>Apply</button>
                                </div>
                                <div className="change-font-size">
                                    <span>Font size<p>{fontVersion}: </p></span>
                                    {fontVersion === "desktop" && <input type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "tablet" && <input type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    {fontVersion === "mobile" && <input type="number" name="" id="" min="1" max="200" defaultValue={text.data.textFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                    <button className="apply-button" onClick={() => applyTextFontSize(text.id)}>Apply</button>
                                </div>
                            </div>
                            <div className="change-color">
                                <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTextColor(e.target.value)} />
                                <button className="apply-button" onClick={() => applyTextColor(text.id)}>Apply</button>
                            </div>
                            <label>Change text to:</label>
                            <input type="text" onChange={(e) => setChangeTextList(e.target.value)} placeholder="Enter text here" />
                            <button className="apply-button" onClick={() => applyTextList(text.id)}>Apply</button>
                            {fontVersion === "desktop" && <li style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSize}px` }}>{text.data.text}</li>}
                            {fontVersion === "tablet" && <li style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMedium}px` }}>{text.data.text}</li>}
                            {fontVersion === "mobile" && <li style={{ color: text.data.textColor, fontFamily: text.data.textFont, fontSize: `${text.data.textFontSizeMobile}px` }}>{text.data.text}</li>}
                        </div>
                    })}
                </ul>
            </div>
            <div className="drs-one-right">
                <div className="drs-one-right-form">
                    {inputs.map((input, index) => {
                        if (input.data.id === "companyName")
                            return <input key={index} ref={ref3} type="text" id="company-name" name="company-name" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderCompany(e.target.value)} />
                    })}
                    {changePlaceholderCompany != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderCompany}>Apply</button></div> : ""}
                    {inputs.map((input, index) => {
                        if (input.data.id === "name")
                            return <input key={index} ref={ref4} type="text" id="name" name="name" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderName(e.target.value)} />
                    })}
                    {changePlaceholderName != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderName}>Apply</button></div> : ""}
                    <select id="review-type" name="review-type" value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)} >
                        <option value="" disabled>Welche Bewertungen möchtest Du löschen?</option>
                        <option value="opt1">Alle 1 Sterne Bewertungen</option>
                        <option value="opt2">Alle 1 und 2 Sterne Bewertungen</option>
                        <option value="opt3">Alle 1 bis 3 Sterne Bewertungen</option>
                        <option value="individual">Individuell</option>
                    </select>
                    {openForm &&
                        <div className="individual-form individual-form-admin">
                            <div className="ind-form-wrap">
                                {!next ? <>
                                    <button className="close-individual-form" onClick={() => setOpenForm(false)}>X</button>
                                    {inputs.map((input, index) => {
                                        if (input.data.id === "individualName")
                                            return <input key={index} ref={ref8} type="text" id="name-of-person" name="name-of-person" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderIndividualName(e.target.value)} />
                                    })}
                                    {changePlaceholderIndividualName != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderIndividualName}>Apply</button></div> : ""}
                                    {inputs.map((input, index) => {
                                        if (input.data.id === "individualReview")
                                            return <input key={index} ref={ref9} type="text" id="review" name="review" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderIndividualReview(e.target.value)} />
                                    })}
                                    {changePlaceholderIndividualReview != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderIndividualReview}>Apply</button></div> : ""}
                                    <div className="ind-next-button"><button className='apply-button' onClick={() => setNext(true)}>Next</button></div>
                                </> :
                                    <div className="individual-form-buttons">
                                        <button className='apply-button' onClick={() => setNext(false)}>Add more</button>
                                        <button className='apply-button' onClick={() => setOpenForm(false)}>Continue</button>
                                    </div>}
                            </div>
                        </div>
                    }
                    {inputs.map((input, index) => {
                        if (input.data.id === "email")
                            return <input key={index} ref={ref5} type="email" id="email" name="email" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderEmail(e.target.value)} />
                    })}
                    {changePlaceholderEmail != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderEmail}>Apply</button></div> : ""}
                    {inputs.map((input, index) => {
                        if (input.data.id === "phone")
                            return <input key={index} ref={ref6} type="tel" id="phone" name="phone" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderPhone(e.target.value)} />
                    })}
                    {changePlaceholderPhone != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderPhone}>Apply</button></div> : ""}
                    <div className="dropdown-reason">
                        <div className="dropdown-select" onClick={() => setIsOpen(!isOpen)}><span>{reason}</span><i className="fa fa-angle-down" style={{ color: "black", fontSize: "0.750rem", fontWeight: "bold" }}></i></div>
                        {isOpen &&
                            <div className="dropdown-box">
                                <ul>
                                    <li onClick={() => handleSelect("Falsche Information")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung enthält ungenaue oder falsche Informationen über Ihr Unternehmen.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Falsche Information</span></li>
                                    <li onClick={() => handleSelect("Verstoß gegen Google-Richtlinien")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung verstößt gegen die Richtlinien von Google (z.B. Spam, Konflikte von Interesse).</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Verstoß gegen Google-Richtlinien</span></li>
                                    <li onClick={() => handleSelect("Beleidigung oder unangemessene Sprache")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung enthält beleidigende oder unangemessene Sprache.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Beleidigung oder unangemessene Sprache</span></li>
                                    <li onClick={() => handleSelect("Irrelevant für das Unternehmen")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung bezieht sich nicht auf das Produkt oder die Dienstleistung Ihres Unternehmens.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Irrelevant für das Unternehmen</span></li>
                                    <li onClick={() => handleSelect("Spam oder unerwünschte Werbung")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung enthält Werbung oder Spam.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Spam oder unerwünschte Werbung</span></li>
                                    <li onClick={() => handleSelect("Doppelte Bewertung")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung ist eine doppelte Abgabe von einem einzelnen Nutzer.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Doppelte Bewertung</span></li>
                                    <li onClick={() => handleSelect("Unfaire oder ungenaue Bewertung")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung ist unangemessen oder basiert auf falschen Annahmen.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Unfaire oder ungenaue Bewertung</span></li>
                                    <li onClick={() => handleSelect("Verstoß gegen Urheberrecht oder andere Rechte")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Die Bewertung verletzt Urheberrechte oder andere gesetzliche Rechte.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Verstoß gegen Urheberrecht oder andere Rechte</span></li>
                                    <li onClick={() => handleSelect("Andere")}><Tooltip title={<span style={{ fontSize: "1rem" }}>Falls keiner der oben genannten Gründe zutrifft, können die Details im Kommentarfeld angegeben werden.</span>} placement='bottom-start' arrow><InfoIcon sx={{ color: "rgb(251, 188, 4)", fontSize: "1.2rem" }} /></Tooltip><span>Andere</span></li>
                                </ul>
                            </div>}
                    </div>
                    {inputs.map((input, index) => {
                        if (input.data.id === "message")
                            return <textarea key={index} ref={ref7} type="text" id="message" name="message" rows="5" placeholder={input.data.placeholder} onChange={(e) => setChangePlaceholderMessage(e.target.value)} />
                    })}
                    {changePlaceholderMessage != "" ? <div className="apply-button-placeholder"><button className="apply-button" onClick={applyPlaceholderMessage}>Apply</button></div> : ""}
                    <div className="change-input-message">
                        {title.map((inputMsg, index) => {
                            return <>
                                <div className="change-font" key={index}>
                                    <div className="change-font-family">
                                        <select name="" id="" onChange={(e) => setChangeInputMessageFont(e.target.value)}>
                                            {fonts.map((font, index) => {
                                                return <option key={index} value={font.data.name}>{font.data.name}</option>
                                            })}
                                        </select>
                                        <button className="apply-button" onClick={() => applyInputMessageFont()}>Apply</button>
                                    </div>
                                    <div className="change-font-size">
                                        <span>Font size<p>{fontVersion}: </p></span>
                                        {fontVersion === "desktop" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={inputMsg.data.inputMessageFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "tablet" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={inputMsg.data.inputMessageFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "mobile" && <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={inputMsg.data.inputMessageFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        <button className="apply-button" onClick={applyInputMessageFontSize}>Apply</button>
                                    </div>
                                </div>
                                <div className="change-color">
                                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeInputMessageColor(e.target.value)} />
                                    <button className="apply-button" onClick={() => applyInputMessageColor()}>Apply</button>
                                </div>
                                <div className="change-message-successfuly">
                                    <input ref={ref12} type="text" onChange={(e) => setChangeInputMessage(e.target.value)} placeholder="Enter text here" />
                                    <button className="apply-button" onClick={() => applyInputMessage()}>Apply</button>
                                </div>
                                {fontVersion === "desktop" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSize}px` }}>{inputMsg.data.inputMessage}</span>}
                                {fontVersion === "tablet" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSizeMedium}px` }}>{inputMsg.data.inputMessage}</span>}
                                {fontVersion === "mobile" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSizeMobile}px` }}>{inputMsg.data.inputMessage}</span>}
                                <div className="change-message-successfuly" style={{ marginTop: "2rem" }}>
                                    <input ref={ref13} type="text" onChange={(e) => setChangeInputCheckboxMessage(e.target.value)} placeholder="Enter checkbox text here" />
                                    <button className="apply-button" onClick={() => applyInputCheckboxMessage()}>Apply</button>
                                </div>
                                {fontVersion === "desktop" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSize}px` }}>{inputMsg.data.inputCheckboxMessage}</span>}
                                {fontVersion === "tablet" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSizeMedium}px` }}>{inputMsg.data.inputCheckboxMessage}</span>}
                                {fontVersion === "mobile" && <span style={{ color: inputMsg.data.inputMessageColor, fontFamily: inputMsg.data.inputMessageFont, fontSize: `${inputMsg.data.inputMessageFontSizeMobile}px` }}>{inputMsg.data.inputCheckboxMessage}</span>}
                            </>
                        })}
                    </div>
                    <div className="successfuly-send">
                        {successfullMessage.map((sucMsg, index) => {
                            return <>
                                <div className="change-font">
                                    <div className="change-font-family">
                                        <select name="" id="" onChange={(e) => setChangeMessageFont(e.target.value)}>
                                            {fonts.map((font, index) => {
                                                return <option key={index} style={{ fontFamily: font.data.name }} value={font.data.name}>{font.data.name}</option>
                                            })}
                                        </select>
                                        <button className="apply-button" onClick={() => applyMessageFont(sucMsg.id)}>Apply</button>
                                    </div>
                                    <div className="change-font-size">
                                        <span>Font size<p>{fontVersion}: </p></span>
                                        {fontVersion === "desktop" && <input type="number" name="" id="" min="1" max="200" defaultValue={sucMsg.data.textSuccMsgFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "tablet" && <input type="number" name="" id="" min="1" max="200" defaultValue={sucMsg.data.textSuccMsgFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        {fontVersion === "mobile" && <input type="number" name="" id="" min="1" max="200" defaultValue={sucMsg.data.textSuccMsgFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />}
                                        <button className="apply-button" onClick={() => applySuccMsgFontSize(sucMsg.id)}>Apply</button>
                                    </div>
                                </div>
                                <div className="change-color">
                                    <input type="color" defaultValue={sucMsg.data.messageBackground} onChange={(e) => setChangeSuccessMessageBackground(e.target.value)} />
                                    <button className="apply-button" onClick={() => applySuccessMessageBackground(sucMsg.id)}>Apply</button>
                                </div>
                                <div className="change-message-successfuly">
                                    <label>Change successfuly message to:</label>
                                    <input ref={ref2} type="text" onChange={(e) => setChangeSuccessMessage(e.target.value)} placeholder="Enter successful sent message text here" />
                                    <button className="apply-button" onClick={() => applySuccessMessage()}>Apply</button>
                                </div>
                                {fontVersion === "desktop" && <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSize}px` }}><span>{sucMsg.data.text}</span></div>}
                                {fontVersion === "tablet" && <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSizeMedium}px` }}><span>{sucMsg.data.text}</span></div>}
                                {fontVersion === "mobile" && <div className="message-sent" key={index} style={{ fontFamily: sucMsg.data.messageFont, backgroundColor: sucMsg.data.messageBackground, fontSize: `${sucMsg.data.textSuccMsgFontSizeMobile}px` }}><span>{sucMsg.data.text}</span></div>}
                            </>
                        })}
                    </div>
                    <button type="submit" value="Send">Jetzt anfragen</button>
                </div>
            </div>
        </div>
    )
}

export default AdminSectionOne