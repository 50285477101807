import React, { useEffect, useRef, useState } from 'react'
import "../../styles/admin styles/AdminSectionFive.css"
import { db } from '../../firebase';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import DoneIcon from '@mui/icons-material/Done';

const AdminSectionFive = () => {

    const [order, setOrder] = useState([])
    const [changePageNumber, setChangePageNumber] = useState()
    const [title, setTitle] = useState([]);
    const [changeTitle, setChangeTitle] = useState("")
    const [changeTitleColor, setChangeTitleColor] = useState("")
    const [changeFontSize, setChangeFontSize] = useState("")
    const [changeBoxOneTextColor, setChangeBoxOneTextColor] = useState("")
    const [changeBoxOneTitleColor, setChangeBoxOneTitleColor] = useState("")
    const [changeBoxOneTitleFont, setChangeBoxOneTitleFont] = useState("")
    const [changeBoxOneTextFont, setChangeBoxOneTextFont] = useState("")
    const [changeBoxTwoTextColor, setChangeBoxTwoTextColor] = useState("")
    const [changeBoxTwoTitleColor, setChangeBoxTwoTitleColor] = useState("")
    const [changeBoxTwoTextFont, setChangeBoxTwoTextFont] = useState("")
    const [changeBoxTwoTitleFont, setChangeBoxTwoTitleFont] = useState("")
    const [changeBoxThreeTextColor, setChangeBoxThreeTextColor] = useState("")
    const [changeBoxThreeTitleColor, setChangeBoxThreeTitleColor] = useState("")
    const [changeBoxThreeTitleFont, setChangeBoxThreeTitleFont] = useState("")
    const [changeBoxThreeTextFont, setChangeBoxThreeTextFont] = useState("")
    const [stepOne, setStepOne] = useState([]);
    const [changeStepOneTitle, setChangeStepOneTitle] = useState("");
    const [changeStepOneText, setChangeStepOneText] = useState("");
    const [stepTwo, setStepTwo] = useState([]);
    const [changeStepTwoTitle, setChangeStepTwoTitle] = useState("");
    const [changeStepTwoText, setChangeStepTwoText] = useState("");
    const [stepThree, setStepThree] = useState([]);
    const [changeStepThreeTitle, setChangeStepThreeTitle] = useState("");
    const [changeStepThreeText, setChangeStepThreeText] = useState("");
    const [fonts, setFonts] = useState([])
    const [changeTitleFont, setChangeTitleFont] = useState("")
    const [fontVersion, setFontVersion] = useState("desktop")

    const ref1 = useRef()
    const ref2 = useRef()
    const ref3 = useRef()
    const ref4 = useRef()
    const ref5 = useRef()
    const ref6 = useRef()
    const ref7 = useRef()

    const applyPageNumber = (id) => {
        db.collection("Order").doc(id).update({
            pageNumber: parseInt(changePageNumber)
        })
    }

    const applyTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").set({
            title: changeTitle
        })
        ref1.current.value = ""
    }

    const applyTitleColor = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
            titleColor: changeTitleColor
        })
    }

    const applyTitleFont = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
            titleFont: changeTitleFont
        })
    }

    const applyFontSize = () => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
                fontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
                fontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").update({
                fontSizeMobile: changeFontSize
            })
        }
    }

    const applyStepOneTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc("TFWeUJFpYzZBoqEkBsqO").update({
            title: changeStepOneTitle
        })
        ref2.current.value = ""
    }

    const applyStepOneText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc("TFWeUJFpYzZBoqEkBsqO").update({
            text: changeStepOneText
        })
        ref3.current.value = ""
    }

    const applyBoxOneTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTitleColor: changeBoxOneTitleColor
        })
    }

    const applyBoxOneTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTitleFont: changeBoxOneTitleFont
        })
    }

    const applyBoxOneTitleFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTitleFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTitleFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTitleFontSizeMobile: changeFontSize
            })
        }
    }

    const applyBoxOneTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            textColor: changeBoxOneTextColor
        })
    }

    const applyBoxOneTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
            boxTextFont: changeBoxOneTextFont
        })
    }

    const applyBoxOneTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTextFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTextFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").doc(id).update({
                boxTextFontSizeMobile: changeFontSize
            })
        }
    }

    const applyBoxTwoTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTitleColor: changeBoxTwoTitleColor
        })
    }

    const applyBoxTwoTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            textColor: changeBoxTwoTextColor
        })
    }

    const applyBoxTwoTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTitleFont: changeBoxTwoTitleFont
        })
    }

    const applyBoxTwoTitleFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTitleFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTitleFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTitleFontSizeMobile: changeFontSize
            })
        }
    }

    const applyBoxTwoTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
            boxTextFont: changeBoxTwoTextFont
        })
    }

    const applyBoxTwoTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTextFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTextFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc(id).update({
                boxTextFontSizeMobile: changeFontSize
            })
        }
    }

    const applyBoxThreeTitleColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTitleColor: changeBoxThreeTitleColor
        })
    }

    const applyBoxThreeTextColor = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            textColor: changeBoxThreeTextColor
        })
    }

    const applyBoxThreeTitleFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTitleFont: changeBoxThreeTitleFont
        })
    }

    const applyBoxThreeTitleFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTitleFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTitleFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTitleFontSizeMobile: changeFontSize
            })
        }
    }

    const applyBoxThreeTextFont = (id) => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
            boxTextFont: changeBoxThreeTextFont
        })
    }

    const applyBoxThreeTextFontSize = (id) => {
        if (fontVersion === "desktop") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTextFontSize: changeFontSize
            })
        }
        if (fontVersion === "tablet") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTextFontSizeMedium: changeFontSize
            })
        }
        if (fontVersion === "mobile") {
            db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc(id).update({
                boxTextFontSizeMobile: changeFontSize
            })
        }
    }

    const applyStepTwoTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc("v2m3RKMv6LDOREcZyrN8").update({
            title: changeStepTwoTitle
        })
        ref4.current.value = ""
    }

    const applyStepTwoText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").doc("v2m3RKMv6LDOREcZyrN8").update({
            text: changeStepTwoText
        })
        ref5.current.value = ""
    }

    const applyStepThreeTitle = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc("jSjlK8v2JNBZs285RVnj").update({
            title: changeStepThreeTitle
        })
        ref6.current.value = ""
    }

    const applyStepThreeText = () => {
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").doc("jSjlK8v2JNBZs285RVnj").update({
            text: changeStepThreeText
        })
        ref7.current.value = ""
    }

    useEffect(() => {
        db.collection("Sequence").onSnapshot(snapshot => {
            setTitle(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-one").onSnapshot(snapshot => {
            setStepOne(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-two").onSnapshot(snapshot => {
            setStepTwo(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Sequence").doc("vD4wR9QgokqlqpKQi0Hw").collection("step-three").onSnapshot(snapshot => {
            setStepThree(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Order").orderBy("pageNumber", "asc").onSnapshot(snapshot => {
            setOrder(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
        db.collection("Fonts").onSnapshot(snapshot => {
            setFonts(snapshot.docs.map(doc => ({ id: doc.id, data: doc.data() })))
        });
    }, [])

    useEffect(() => {
        const windowWidth = () => {
            if (window.outerWidth > 1200) {
                setFontVersion("desktop")
            }
            if (window.outerWidth < 1200 && window.outerWidth > 721) {
                setFontVersion("tablet")
            }
            if (window.outerWidth < 721) {
                setFontVersion("mobile")
            }
        }

        windowWidth()

        window.addEventListener("resize", windowWidth)
        return _ => {
            window.removeEventListener('resize', windowWidth)
        }
    }, [])

    return (
        <div className="delete-reviews-section-five">
            {order.map((order, index) => {
                if (order.data.pageTitle === "Sequence")
                    return <div className="page-number" key={index}>
                        <span>{order.data.pageNumber}</span>
                        <div className="page-number-inputs">
                            <input type="number" placeholder="Change page number" onChange={(e) => setChangePageNumber(e.target.value)} />
                            <button className="apply-button" onClick={() => applyPageNumber(order.id)}>Apply</button>
                        </div>
                    </div>
            })}
            <div className="change-title-sequence">
                <div className="change-font">
                    <div className="change-font-family">
                        <select name="" id="" onChange={(e) => setChangeTitleFont(e.target.value)}>
                            {fonts.map((font, index) => {
                                return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                            })}
                        </select>
                        <button className="apply-button" onClick={applyTitleFont}>Apply</button>
                    </div>
                    <div className="change-font-size">
                        <span>Font size<p>{fontVersion}: </p></span>
                        {title.map((fontSize, index) => {
                            if (fontVersion === "desktop") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                            if (fontVersion === "tablet") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                            if (fontVersion === "mobile") {
                                return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={fontSize.data.fontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                            }
                        })}
                        <button className="apply-button" onClick={applyFontSize}>Apply</button>
                    </div>
                </div>
                <div className="change-color">
                    <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeTitleColor(e.target.value)} />
                    <button className="apply-button" onClick={applyTitleColor}>Apply</button>
                </div>
                <label>Change title to:</label>
                <input ref={ref1} type="text" onChange={(e) => setChangeTitle(e.target.value)} placeholder="Enter title here" />
                <button className="apply-button" onClick={applyTitle}>Apply</button>
                {title.map((title, index) => {
                    if (fontVersion === "desktop") {
                        return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSize}px` }} key={index}>{title.data.title}</h1>
                    }
                    if (fontVersion === "tablet") {
                        return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMedium}px` }} key={index}>{title.data.title}</h1>
                    }
                    if (fontVersion === "mobile") {
                        return <h1 style={{ color: title.data.titleColor, fontFamily: title.data.titleFont, fontSize: `${title.data.fontSizeMobile}px` }} key={index}>{title.data.title}</h1>
                    }
                })}
            </div>
            <div className="drs-five-steps">
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxOneTitleFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxOneTitleFont("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepOne.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxOneTitleFontSize("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                            </div>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxOneTextFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxOneTextFont("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepOne.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxOneTextFontSize("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                            </div>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxOneTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxOneTitleColor("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxOneTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxOneTextColor("TFWeUJFpYzZBoqEkBsqO")}>Apply</button>
                        </div>
                        <span><span className="step-num">1</span> <BusinessCenterIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref2} type="text" placeholder="Change step title" onChange={(e) => setChangeStepOneTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepOneTitle}>Apply</button>
                        {stepOne.map((step, index) => {
                            if (fontVersion === "desktop") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "tablet") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "mobile") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                            }
                        })}
                    </div>
                    <textarea ref={ref3} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepOneText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepOneText}>Apply</button>
                    {stepOne.map((step, index) => {
                        if (fontVersion === "desktop") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "tablet") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "mobile") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                        }
                    })}
                </div>
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxTwoTitleFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxTwoTitleFont("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepTwo.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxTwoTitleFontSize("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                            </div>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxTwoTextFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxTwoTextFont("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepTwo.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxTwoTextFontSize("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                            </div>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxTwoTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxTwoTitleColor("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxTwoTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxTwoTextColor("v2m3RKMv6LDOREcZyrN8")}>Apply</button>
                        </div>
                        <span><span className="step-num">2</span> <AutoDeleteIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref4} type="text" placeholder="Change step title" onChange={(e) => setChangeStepTwoTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepTwoTitle}>Apply</button>
                        {stepTwo.map((step, index) => {
                            if (fontVersion === "desktop") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "tablet") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "mobile") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                            }
                        })}
                    </div>
                    <textarea ref={ref5} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepTwoText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepTwoText}>Apply</button>
                    {stepTwo.map((step, index) => {
                        if (fontVersion === "desktop") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "tablet") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "mobile") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                        }
                    })}
                </div>
                <div className="drs-five-step admin-step">
                    <div className="drs-five-step-top admin-step-top">
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Title font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxThreeTitleFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxThreeTitleFont("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepThree.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTitleFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxThreeTitleFontSize("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                            </div>
                        </div>
                        <span style={{ marginTop: "10px", fontSize: "1rem", fontWeight: "400" }}>Text font: </span>
                        <div className="change-font" style={{ flexDirection: "column" }}>
                            <div className="change-font-family">
                                <select name="" id="" onChange={(e) => setChangeBoxThreeTextFont(e.target.value)} style={{ fontSize: "1rem" }}>
                                    {fonts.map((font, index) => {
                                        return <option key={index} value={font.data.name} style={{ fontFamily: font.data.name }}>{font.data.name}</option>
                                    })}
                                </select>
                                <button className="apply-button" style={{ fontSize: "1rem" }} onClick={() => applyBoxThreeTextFont("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                            </div>
                            <div className="change-font-size">
                                <span>Font size<p>{fontVersion}: </p></span>
                                {stepThree.map((step, index) => {
                                    if (fontVersion === "desktop") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSize} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "tablet") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMedium} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                    if (fontVersion === "mobile") {
                                        return <input key={index} type="number" name="" id="" min="1" max="200" defaultValue={step.data.boxTextFontSizeMobile} onChange={(e) => setChangeFontSize(e.target.value)} />
                                    }
                                })}
                                <button className="apply-button" onClick={() => applyBoxThreeTextFontSize("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                            </div>
                        </div>
                        <div className="change-color afcc" style={{ marginTop: "1rem" }}>
                            <label>Change title color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxThreeTitleColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxThreeTitleColor("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <div className="change-color afcc">
                            <label>Change text color</label>
                            <input type="color" defaultValue="#ffffff" onChange={(e) => setChangeBoxThreeTextColor(e.target.value)} />
                            <button className="apply-button" onClick={() => applyBoxThreeTextColor("jSjlK8v2JNBZs285RVnj")}>Apply</button>
                        </div>
                        <span><span className="step-num">3</span> <DoneIcon sx={{ fontSize: 30 }} /></span>
                        <input ref={ref6} type="text" placeholder="Change step title" onChange={(e) => setChangeStepThreeTitle(e.target.value)} />
                        <button className="apply-button" onClick={applyStepThreeTitle}>Apply</button>
                        {stepThree.map((step, index) => {
                            if (fontVersion === "desktop") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSize}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "tablet") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMedium}px` }} key={index}>{step.data.title}</p>
                            }
                            if (fontVersion === "mobile") {
                                return <p style={{ color: step.data.boxTitleColor, fontFamily: step.data.boxTitleFont, fontSize: `${step.data.boxTitleFontSizeMobile}px` }} key={index}>{step.data.title}</p>
                            }
                        })}
                    </div>
                    <textarea ref={ref7} rows={3} type="text" placeholder="Change step text" onChange={(e) => setChangeStepThreeText(e.target.value)} />
                    <button className="apply-button" onClick={applyStepThreeText}>Apply</button>
                    {stepThree.map((step, index) => {
                        if (fontVersion === "desktop") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSize}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "tablet") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMedium}px` }} key={index}>{step.data.text}</p>
                        }
                        if (fontVersion === "mobile") {
                            return <p style={{ color: step.data.textColor, fontFamily: step.data.boxTextFont, fontSize: `${step.data.boxTextFontSizeMobile}px` }} key={index}>{step.data.text}</p>
                        }
                    })}
                </div>
            </div>
        </div>
    )
}

export default AdminSectionFive